.scaleOut-enter {
  transform: scale(1.1);
}

.scaleOut-enter-active {
  transform: scale(1);
  transition: transform 150ms ease-out;
}

.scaleOut-exit {
  display: none;
}
