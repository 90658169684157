html, body {
  height: 100%;
}

body {
  overflow: hidden;
}

#root {
  height: 100%;
  display: flex;
}

body > img {
  position: absolute;
  top: 0;
  left: 0;
}

.scaleOut-enter {
  transform: scale(1.1);
}

.scaleOut-enter-active {
  transition: transform .15s ease-out;
  transform: scale(1);
}

.scaleOut-exit {
  display: none;
}

/*# sourceMappingURL=index.d6faf42d.css.map */
