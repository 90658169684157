/** Global styles according to [react-native-web docs](https://necolas.github.io/react-native-web/docs/setup/#root-element) */

/* These styles make the body full-height */
html,
body {
  height: 100%;
}
/* These styles disable body scrolling if you are using <ScrollView> */
body {
  overflow: hidden;
}
/* These styles make the root element full-height */
#root {
  display: flex;
  height: 100%;
}

/* Hide <img> elements created by Analytics SDK */
body > img {
  position: absolute;
  top: 0;
  left: 0;
}
